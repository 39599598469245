var my_app = {};

// Define constants
my_app.MOBILE_MAX_WIDTH = 768;
my_app.TABLET_MAX_WIDTH = 992;
my_app.DEFAULT_DURATION = 300;
my_app.HEADER_HEIGHT = 0;

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
    sParameterName;

    sParameterName = sPageURL.split('=');

    if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }

};

jQuery(document).ready(function($) {
    //To go to pages anchors
    var autoscroll = getUrlParameter('autoscroll');

    if (autoscroll) {
        window.scrollTo(0, $(':target').offset().top - $('#navbarBox').outerHeight( true ));
    }


    // Init interface
    $('#partnersGallery').owlCarousel({
        loop: false,
        autoplay: false,
        nav: true,
        navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
        dots: false,
        margin: 15,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 4,
            }
        }
    });

    // Init interface
    $('#articlesGallery').owlCarousel({
        loop: true,
        autoplay: false,
        nav: true,
        navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
        dots: false,
        margin: 15,
        items: 1,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: $('.owl-carousel .item').length > 1 ? 2:1,
            },
        }
    });


    //Init lightcase
    $('a[data-rel^=lightcase]').lightcase({
        maxWidth: 1024,
        maxHeight: 768,
        video: {
            width: 640,
            height: 360,
        },
        onBeforeShow : {
            hackZIndex: function() {
                // hack z-index lightcase component as modification in css does not seems to apply (see _custom.scss)
                $('#lightcase-overlay').css('z-index', '2000');
                $('#lightcase-loading').css('z-index', '2001');
                $('#lightcase-case').css('z-index', '2002');
                $('#lightcase-nav').css('z-index', '2003');
                $('a[class^="lightcase-icon-"]').css('z-index', '2004');
            }
        },
    });
});

jQuery(window).scroll(function() {

});

jQuery(window).resize(function() {

});
